.App {
  text-align: center;
}

@media (max-width: 767px) { /* Smartphone, wie iPhone XR */
  .main {
    align-items: center;
    max-width: 100vw;
    width: 100vw;
    overflow-x: hidden;

  }
}

.card-uebung{
  margin:5vw;
  width: 90vw;
}
.custom-container {
  margin-left: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

}

.after-login-container {
background: linear-gradient(to bottom, #84fab0, #8fd3f4);
width: 100vw;
height: 94vh;
padding-top: 5vw;
padding-left: 5vw;
padding-right: 5vw;
overflow: auto;
padding-bottom: 5vw;

display: flex;
flex-wrap: wrap;
justify-content: space-between;
}

@media (max-width: 767px) { /* Smartphone, wie iPhone XR */
  .after-login-container {
    max-width: 100%;
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
    padding-top:30vw;
    padding-bottom:30vw;
  }
}
