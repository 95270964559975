html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

#startScreenOtherElements{
  padding-left: 10vw;
}

.platform-table {
  margin: auto;
  width: fit-content;
}

table {
  border-collapse: collapse;
}

.slide-container {
  position: relative;
}

.caption-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.727);
  z-index: 1;
}

.caption-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #fff;
  text-align: center;
}

.caption-text h3 {
  font-size: 24px;
}

.caption-text p {
  font-size: 18px;
}


td {
  text-align: center;
  padding: 10px;
}

.icon {
  font-size: 48px;
  display: block;
  margin: 0 auto;
}

@media (max-width: 480px) {
  .icon {
    font-size: 36px;
  }
}



.auth-container {
  display: flex;
  align-items: center;
  height: 60vh;
  width: 100vw;
  padding: 0px;
  left: 0px;
}

.auth-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

.auth-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Ändere die Ausrichtung auf "center" */
  align-items: center; /* Zentriere vertikal */
  padding: 20px;
  border-radius: 20px;
  position: relative;
  z-index: 102;
  animation: fadeIn 2s;
  width: 300px;  
  margin: 20px auto; /* Zentriere den Container */
}

.auth-card {
  width: 100%;
}

.form-control {
  margin-top: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.description {
  justify-content: flex-start;
  padding: 2vw;
  color: #000;
  max-width: 400px;
  animation: slideIn 4s;
  text-align: left;
  margin-bottom: 20px;
}

#platform-features {
  width: 80vw;
  margin: 20px auto; /* Zentriere die Plattform-Features */
}

@media (max-width: 767px) {
  html, body {
    overflow-x: hidden;
  }

  .auth-container {
    align-items: center !important;
    max-width: 100%;
    overflow-x: hidden;
    display: flex !important;
    height: 100vh;
    position: relative !important;
    width: 100vw;
    padding:0px;
    left:0px;
    flex-direction: column;
  }

  .auth-card-container {
    width: 95%;
    margin: 20px auto;
  }

  .description {
    padding: 5vw;
    padding-top: 40vw;
    max-width: none;
  }
  
  .auth-container::before {
    height: 120vh !important;
    background: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
    background-size: auto;
  }
}
